import {LitElement, html, css} from 'lit';
import '@material/mwc-button';
import {status} from '../elements/statuses';
import {sharedStyles} from '../shared-styles';
import {datadogRum} from '@datadog/browser-rum';
import '../elements/upload-item';

/**
 * `price-discount-page`
 *  Display the uploader for product price discounts files
 */
export class PriceDiscountPage extends LitElement {
  /**
   * Defines the elements styles
   *
   * @return {CSSResult} the resulting styles
   */
  static get styles() {
    const style = css`
      small {
        display: block;
        margin: 10px;
      }

      small>a {
        display: block;
      }

      input[type="datetime-local"] {
        width: 25%;
      }
      `;
    return [sharedStyles, style];
  }

  /**
   * Defined the elements content
   *
   * @return {TemplateResult} the resulting html template
   */
  render() {
    return html`
    <h2>Price Discount Uploader</h2>
    <form>
      <label>
        Would you like to schedule when these discounts go live? (optional)
        <input name="scheduledTime" type="datetime-local" min=${PriceDiscountPage.minScheduledTime}  />
      </label>
      <label>
        Upload a Price Discount CSV file
        <input name="upload" type="file" accept=".csv" required />
      </label>
      <small>The CSV file is expected in a specific format.
        <a href="/templates/international-price-discounts.csv"
         download="price-discounts.csv">Download the International template here.</a>
        <a href="/templates/north-america-price-discounts.csv"
         download="price-discounts.csv">Download the North America template here.</a>
         <ul>
          <li>You can include one or more store code columns, a column for each store you wish to discount</li>
          <li>If you do not wish to discount a store, then do not include the store column in the file</li>
          <li>Each row must have the Customer SKU and Vendor SKU</li>
          <li>Values must not include any currency or percentage symbols</li>
          <li>The value is the discounted price of product</li>
          <li>If you wish to remove a discount, provide the store column and leave the value blank</li>
          <li>Prices will be discounted or have the discount removed in Shopify within minutes of upload or the scheduled time you have chosen</li>
          <li>The scheduled time is in your local time zone</li>
          <li><i>Any blank values will trigger the removal of discount for that Customer SKU</i></li>
         </ul>
      </small>
      <mwc-button class="submit" raised @click=${this.handleUpload}>
        Upload
      </mwc-button>
      <mwc-button class="cancel" @click=${this.resetForm}>
        Clear
      </mwc-button>
      <p class="error" ?hidden=${this.state !== status.Errored}>Oops there has been an error uploading. ${this.error?.error || this.error}</p>
      <p class="saving" ?hidden=${this.state !== status.Saving}>Uploading file.</p>
    </form>
    <h2>Discount Uploads History</h2>
    <mwc-button class="view" raised @click=${this.getDiscountUploads}>
      View Uploads
    </mwc-button>
    <div class="uploads">
      ${this.discountUploads.map((upload) => html`<upload-item .service=${this.service} .upload=${upload}></upload-item>`)}
    </div>
    <p class="error" ?hidden=${this.state !== status.Errored}>There has been an error getting the uploads. ${this.error?.error || this.error}</p>
    <p class="loading" ?hidden=${this.state !== status.Loading}>Loading uploads.</p>
  `;
  }

  /**
   * Defines the elements properties
   *
   * @return {object} the props
   */
  static get properties() {
    return {
      /** The data parsed from the route url */
      routeData: {type: Object},
      /** The service for getting data */
      service: {type: Object},
      /** What state we are in */
      state: {type: String},
      /** Any error from the API */
      error: {type: Object},
      /** The list of discount upload files */
      discountUploads: {type: Array},
    };
  }

  /** Initialises values of properties */
  constructor() {
    super();
    this.routeData = {};
    this.service = {};
    this.state = status.Idle;
    this.error = {};
    this.discountUploads = [];
  }

  /**
   * Helper to get the form element
   * @return {HTMLElement} the form element
   */
  get form() {
    return this.shadowRoot.querySelector('form');
  }

  /**
   * Wrapper to reset the form and state
   */
  resetForm() {
    this.form.reset();
    this.state = status.Idle;
  }

  /**
   * Retrieves price discount uploads
   */
  async getDiscountUploads() {
    try {
      this.state = status.Loading;
      let uploads = await this.service.send('/uploads/discounts', 'GET');
      uploads = uploads.sort((a, b) => {
        return new Date(b.uploadDate) - new Date(a.uploadDate);
      });
      this.discountUploads = uploads;
      this.state = status.Loaded;
    } catch (e) {
      this.state = status.Errored;
      this.error = e;
      datadogRum.addError(e, {source: 'price-discount-page'});
    }
  }

  /**
   * Handles the submission of the form, getting the data from the form and sending it to the service
   *
   * If a scheduled time is set, it is converted to ISO format before sending so we don't need to deal with timezones
   *
   * @param {Event} event the submission event
   */
  async handleUpload(event) {
    event.preventDefault();
    try {
      this.state = status.Saving;
      const valid = this.form.reportValidity();
      if (!valid) {
        return;
      }

      const formdata = this.getFormDataWithConvertedTime();
      await this.service.send('/uploads/discounts', 'POST', formdata);
      this.state = status.Idle;
    } catch (e) {
      this.state = status.Errored;
      this.error = e;
      datadogRum.addError(e, {source: 'price-discount-page'});
    }
  }

  /**
   * Converts the scheduled time to ISO format and get the formdata
   *
   * @return {FormData} the form data with the scheduled time converted to ISO format
   */
  getFormDataWithConvertedTime() {
    const formdata = new FormData(this.form);

    const scheduledTime = formdata.get('scheduledTime');
    if (scheduledTime) {
      formdata.set('scheduledTime', new Date(scheduledTime).toISOString());
    }

    return formdata;
  }

  /**
   * Gets the minimum scheduled time, 30 minutes from now
   *
   * @return {string} the minimum scheduled time
   */
  static get minScheduledTime() {
    const now = new Date();
    now.setMinutes(now.getMinutes() + 30);
    const time = now.toLocaleTimeString('en-GB').substring(0, 5);
    const date = now.toLocaleDateString('en-GB').split('/').reverse().join('-');
    return `${date}T${time}`;
  }
}
window.customElements.define('price-discount-page', PriceDiscountPage);
