import {LitElement, html, css} from 'lit';
import '@material/mwc-button';
import {status} from '../elements/statuses';
import {sharedStyles} from '../shared-styles';
import {datadogRum} from '@datadog/browser-rum';
import '../elements/upload-item';

/**
 * `price-updates-page`
 *  Display the uploader for price updates files
 */
export class PriceUpdatesPage extends LitElement {
  /**
   * Defines the elements styles
   *
   * @return {CSSResult} the resulting styles
   */
  static get styles() {
    const style = css`
      small {
        display: block;
        margin: 10px;
      }

      small>a {
        display: block;
      }`;
    return [sharedStyles, style];
  }

  /**
   * Defined the elements content
   *
   * @return {TemplateResult} the resulting html template
   */
  render() {
    return html`
    <h2>Price Updates Uploader</h2>
    <form>
      <label>
        Upload a Price Updates CSV file
        <input name="upload" type="file" accept=".csv" required />
      </label>
      <small>The CSV file is expected in a specific format.
        <a href="/templates/international-price-updates-unclesku.csv"
         download="price-updates.csv">Download the International template to update prices by Uncle SKU here.</a>
        <a href="/templates/north-america-price-updates-unclesku.csv"
         download="price-updates.csv">Download the North America template to update prices by Uncle SKU here.</a>
        <a href="/templates/international-price-updates-customersku.csv"
         download="price-updates.csv">Download the International template to update prices by Customer SKU here.</a>
        <a href="/templates/north-america-price-updates-customersku.csv"
         download="price-updates.csv">Download the North America template to update prices by Customer SKU here.</a>
         <ul>
          <li>You can include one or more store code columns, a column for each store you wish to update</li>
          <li>If you do not wish to update a store, then do not include the store column in the file</li>
          <li>Each row must have the Customer SKU and Vendor SKU</li>
          <li>Values cannot be left blank or 0, and must not include any currency symbols</li>
          <li>Prices will be updated in Shopify within minutes of upload</li>
          <li>Prices will be updated in Netsuite within 15 - 30 minutes of upload, due to Netsuite processing time</li>
         </ul>
      </small>
      <mwc-button class="submit" raised @click=${this.handleUpload}>
        Upload
      </mwc-button>
      <mwc-button class="cancel" @click=${this.resetForm}>
        Clear
      </mwc-button>
      <p class="error" ?hidden=${this.state !== status.Errored}>Oops there has been an error uploading. ${this.error?.error || this.error}</p>
      <p class="saving" ?hidden=${this.state !== status.Saving}>Uploading file.</p>
    </form>
    <h2>Price Uploads History</h2>
    <mwc-button class="view" raised @click=${this.getPriceUploads}>
      View Uploads
    </mwc-button>
      <div class="uploads">
        ${this.priceUploads.map((upload) => html`<upload-item .service=${this.service} .upload=${upload}></upload-item>`)}
      </div>
      <p class="error" ?hidden=${this.state !== status.Errored}>There has been an error getting the uploads. ${this.error?.error || this.error}</p>
      <p class="loading" ?hidden=${this.state !== status.Loading}>Loading uploads.</p>
  `;
  }

  /**
   * Defines the elements properties
   *
   * @return {object} the props
   */
  static get properties() {
    return {
      /** The data parsed from the route url */
      routeData: {type: Object},
      /** The service for getting data */
      service: {type: Object},
      /** What state we are in */
      state: {type: String},
      /** Any error from the API */
      error: {type: Object},
      /** The list of price upload files */
      priceUploads: {type: Array},
    };
  }

  /** Initialises values of properties */
  constructor() {
    super();
    this.routeData = {};
    this.service = {};
    this.state = status.Idle;
    this.priceUploads = [];
    this.error = {};
  }

  /**
   * Helper to get the form element
   * @return {HTMLElement} the form element
   */
  get form() {
    return this.shadowRoot.querySelector('form');
  }

  /**
   * Wrapper to reset the form and state
   */
  resetForm() {
    this.form.reset();
    this.state = status.Idle;
  }

  /**
   * Retrieves price uploads
   */
  async getPriceUploads() {
    try {
      this.state = status.Loading;
      let uploads = await this.service.send('/uploads/prices', 'GET');
      uploads = uploads.sort((a, b) => {
        return new Date(b.uploadDate) - new Date(a.uploadDate);
      });
      this.priceUploads = uploads;
      this.state = status.Loaded;
    } catch (e) {
      this.state = status.Errored;
      this.error = e;
      datadogRum.addError(e, {source: 'price-updates-page'});
    }
  }

  /**
     * Handles the submission of the form, getting the data from the form and sending it to the service
     *
     * @param {Event} event the submission event
     */
  async handleUpload(event) {
    event.preventDefault();
    try {
      this.state = status.Saving;
      const valid = this.form.reportValidity();
      if (!valid) {
        return;
      }
      const formdata = new FormData(this.form);
      await this.service.send('/uploads/prices', 'POST', formdata);
      this.state = status.Idle;
    } catch (e) {
      this.state = status.Errored;
      this.error = e;
      datadogRum.addError(e, {source: 'price-updates-page'});
    }
  }
}
window.customElements.define('price-updates-page', PriceUpdatesPage);
