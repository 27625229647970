import {css} from 'lit';

/* shared styles for all views */
export const sharedStyles = css`
a {
  color: var(--black);
  font-weight: 500;
}

a:visited {
  color: var(--black);
}

label {
    font-family: 'Roboto', sans-serif;
    display: flex;
    flex-direction: column;
    text-align: left;
    margin: 10px 0;
}

input {
    font-family: inherit;
    border: 1px solid #ccc;
    border-radius: 3px;
    background: var(--white);
    padding: 10px;
    color: var(--black);
}

input::placeholder {
    color: #ccc;
}

input:focus {
    outline: none;
    border-color: var(--black);
    -webkit-transition: border-color ease 300ms;
    transition: border-color ease 300ms;
}

input:disabled {
    opacity: 0.7;
}

@media (prefers-color-scheme: dark) {
  a {
    color: var(--white);
    font-weight: 500;
  }

  a:visited {
    color: var(--white);
  }
}
`;
