import {LitElement, html, css} from 'lit';
import {sharedStyles} from '../shared-styles';
/**
 * `home-page`
 *  The first page the user sees
 */
export class HomePage extends LitElement {
  /**
   * Defines the elements styles
   *
   * @return {CSSResult} the resulting styles
   */
  static get styles() {
    const style = css`
      ul.links {
        list-style: none;
      }

      ul.links>li {
        margin: 25px 0;
      }
    `;
    return [sharedStyles, style];
  }

  /**
   * Defined the elements content
   *
   * @return {TemplateResult} the resulting html template
   */
  render() {
    return html`
<h2>What would you like to do?</h2>
<ul class="links">
    <li>
        <a class="button" href="/intake-rrp-conversion">
            <mwc-button raised>Upload Intake RRP Conversions</mwc-button>
        </a>
    </li>
    <li>
        <a class="button" href="/price-updates">
            <mwc-button raised>Update Prices</mwc-button>
        </a>
    </li>
    <li>
        <a class="button" href="/price-discounts">
            <mwc-button raised>Upload Price Discounts</mwc-button>
        </a>
    </li>
    <li>
        <a class="button" href="/schedule-management">
            <mwc-button raised>Manage Schedules</mwc-button>
        </a>
    </li>
</ul>`;
  }
}
window.customElements.define('home-page', HomePage);
